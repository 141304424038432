<template>
  <div class="detailed-list-store">
    <div class="detailed-list-store-title">
      <span>保罗标物旗舰店</span>
    </div>
    <div class="detailed-list-store-content">
      <store-wares></store-wares>
    </div>
  </div>
</template>

<script>
  import store_wares from './settlement-detailed-list-store-wares';

  export default {
    components: {
      "store-wares": store_wares
    }
  }
</script>

<style lang="scss" scoped>
  .detailed-list-store {
    background: #e2ecf6;
    padding: 50px 47px;
    margin-bottom: 20px;

    &-title {
      font-size: 12px;
      font-weight: 800;

      &:before {
        content: "商家：";
      }
    }

    &-input {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      height: 35px;
      line-height: 35px;
      >span {
        width: 55px;
      }
      >input{
        flex: 100;
        border: 1px solid #89adcd;
        padding: 0px 10px;
        &:focus{
          outline: none;
        }
      }
    }

    &-content {
      width: 100%;
    }
  }
</style>
