<template>
  <div class="detailed-list-store-wares">
    <div class="wares-image">
      <img />
    </div>
    <div class="wares-infow">
      <div class="infow-name">
        <div class="infow-name-text">
          <span>菜籽油/脂肪酸 菜籽油/脂肪酸菜籽油/脂肪酸菜籽油/脂肪酸菜籽油/脂肪酸菜籽油/脂肪酸菜籽油/脂肪酸</span>
        </div>
        <div class="infow-name-attr">
          <span>颜色：</span>
          <span>两瓶装</span>
        </div>
        <div class="infow-name-attr">
          <span>颜色：</span>
          <span>两瓶装</span>
        </div>
        <div class="infow-name-other">
        </div>
      </div>
      <div class="infow-price">
        <span>320.00</span>
      </div>
      <div class="infow-number">
        <span>1</span>
      </div>`
      <div class="infow-stock">
        <span>有货</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .detailed-list-store-wares {
    width: 100%;
    height: 120px;
    margin: 20px 0px;
    display: inline-flex;

    .wares-image {
      display: inline-block;
      width: 130px;
      height: 100%;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    .wares-infow {
      display: inline-flex;
      height: 100%;

      .infow-name {
        width: 280px;
        margin: 0px 40px;

        .infow-name-text {
          width: 100%;
          height: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 19px;
        }

        .infow-name-attr {
          margin-bottom: 10px;
        }

        .infow-name-other {
          color: #8aadcd;
        }


      }

      .infow-price {
        margin: 0px 40px;
        color: #ef8120;

        &:before {
          content: "￥";
        }
      }

      .infow-number {
        margin: 0px 40px;

        &:before {
          content: "x";
        }
      }
    }
  }
</style>
