<template>
  <div class="block-width w-1920">
    <div class="logo w-1200">
      <div class="logo-item" @click="handleBackHome">
        <div class="logo-item-image">
          <img src="../../assets/login/logo.gif" />
        </div>
        <svg class="logo-item-store-image" height="25">
          <text x="10" y="20">结算页</text>
        </svg>
      </div>
      <div class="logo-search">
      </div>
    </div>
  </div>

</template>
<script>
  export default {
    methods: {
      handleBackHome() {
        this.$router.push({
          name: "Home"
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
  .block-width {
    position: relative;
    height: 104px;
    width: 100%;
    background: #fff;

    .logo {
      width: 1237px;
      height: 100%;
      margin: auto;
      // padding: 0px 30px;
      position: relative;

      &-item {
        cursor: pointer;
        width: 279px;
        height: 60px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
        // display: inline-flex;

        &-store-image {
          font-size: 20px;
          font-weight: 600;
          fill: #2659f2;
          position: absolute;
          top: 0px;
          bottom: 0px;
          margin: auto;
          width: 80px;
        }

        &-image {
          width: 159px;
          height: 100%;
          position: relative;
          display: inline-block;

          >img {
            width: 159px;
            height: 47px;
            display: inline-block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
      }

      &-search {
        position: absolute;
        width: 390px;
        height: 80px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;

        &-progress {
          width: 100%;
          height: 104px;
        }
      }
    }
  }
</style>
