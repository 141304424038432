<template>
  <el-dialog
    title="负责客户"
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
  >
    <div class="search">
      <input type="text" v-model="username" placeholder="输入账号或所属公司" />
      <div class="fangdajing" @click="initData">
        <Uimage src="../../../assets/order/fangdajing.png" alt="" />
      </div>
    </div>
    <el-table
      :data="data"
      ref="singleTable"
      highlight-current-row
      @current-change="handleCurrentChange"
    >
      <el-table-column property="username" label="账号"></el-table-column>
      <el-table-column property="companyName" label="所属公司"></el-table-column>
      <el-table-column property="phone" label="电话"></el-table-column>
      <el-table-column property="email" label="邮箱"></el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogTableVisible = false" size="mini"
        >取 消</el-button
      >
      <el-button size="mini" type="primary" @click="confirm()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getMemberByManagerId } from '@/api/member';

export default {
  name: 'settlement-member',
  data() {
    return {
      username:'',
      dialogTableVisible: false,
      data: null,
      currentRow: null,
    };
  },
  methods: {
    initData() {
      getMemberByManagerId(this.username).then((res) => {
        this.data = res.data.list;
        this.currentRow = null;
      });
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    confirm() {
      if (this.currentRow) {
        this.$emit('selectMember', this.currentRow.memberId);
      }else {
        this.$emit('selectMember',null);
      }
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  font-size: 16px;
      margin-right: 150px;
      display: flex;

      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }

      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor:pointer;
      }
    }

</style>
<style>
  tr.current-row>td {
      background-color: #d0effb !important;
    }
</style>
