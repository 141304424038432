import {get,post, request} from '@/utils/request';


/***
 * 获取会员收货地址
 */
const getDeliveryAddressList = function (data){
    return get(`/api/address/selReceiverAddressList?pageSize=100&receiverAddress=`+data)
        .then(res=>res.data).catch(err=>console.log(err))
}

export {getDeliveryAddressList}
