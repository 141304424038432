<template>
<div>


  <div class="settlement-consignee">
    <div class="title">
      <span>收货人信息</span>
      <button class="title-add" @click="add">
        <span>新增收货地址</span>
      </button>
    </div>
    <div class="title">
      <el-input
        placeholder="请输入查询内容"
        v-model="searchVal"/>
        <el-button style="margin-left: 10px;" icon="el-icon-search" size="small" circle @click="onSearchReceiver()"/>
    </div>
    <div class="content">
      <dl
        class="address-list"
        v-for="(item, index) in list"
        :key="item.receiverId"
      >
        <dt
          class="address-btn"
          :class="index == selectIndex ? 'address-btn-select' : ''"
          @click="selectAddress(index)"
        >
          <a :title="item.receiverName" href="javascript:void(0);">{{ item.receiverName }}</a>
        </dt>
        <dd class="address-content">
          <span>{{ item.receiverName }}</span>
          <span>{{ item.receiverProvince }}</span>
          <span>{{ item.receiverCity }}</span>
          <span>{{ item.receiverDistrict }}</span>
          <span>{{ item.receiverAddress }}</span>
          <span>{{ item.receiverPhone }}</span>
          <el-tag type="info" size="mini" v-if="item.isDefault == 1"
            >默认</el-tag
          >
        </dd>
      </dl>
    </div>

  </div>
  <el-dialog title="新增收货地址" :visible.sync="dialogFormVisible" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="收货人" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号码"
          :label-width="formLabelWidth"
          prop="number"
          ><el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在省" :label-width="formLabelWidth" prop="valueProvince">
          <el-select style="width: 100%;" v-model="form.valueProvince" filterable placeholder="请选择省" @change="changeProvince">
            <el-option
                    v-for="item in provinceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在市" :label-width="formLabelWidth" prop="valueCity">
          <el-select style="width: 100%;" v-model="form.valueCity" filterable placeholder="请选择市" @change="changeCity">
            <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区" :label-width="formLabelWidth" prop="valueOrigin">
          <el-select style="width: 100%;" v-model="form.valueOrigin" filterable placeholder="请选择区" @change="changeOrigin">
            <el-option
                    v-for="item in originOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="详细地址"
          :label-width="formLabelWidth"
          prop="xiangxi"
        >
          <el-input
            type="textarea"
            v-model="form.xiangxi"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="固定电话"
          :label-width="formLabelWidth"
          prop="dianhua"
        >
          <el-input v-model="form.dianhua" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="邮政编码"
          :label-width="formLabelWidth"
          prop="email"
        >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import settlement_consignee_node from './settlement-consignee-node';
import { getDeliveryAddressList } from '@/api/address.js';
import {chinaAddress} from '../../utils/china_address.js';
import { post, get } from '@/utils/request';
export default {
  props: {
    showLine: Number,
  },
  data() {
    return {
      list: [],
      searchVal:'',
      selectIndex: 0,
      adddress: {},
      dialogFormVisible: false,
      formLabelWidth: '120px',
      form: {
        name: '',
        regionId:'',
        region: '',
        xiangxi: '',
        number: '',
        dianhua: '',
        email: '',
        bieming: '',
        shiId:'',
        shi: '',
        quId:'',
        qu: '',
        valueProvince:'',
        valueCity:'',
        valueOrigin:''
      },
      provinceList: [], // 省列表
      cityList: [], // 市列表
      originList: [], // 区列表
      valueProvince: '', // 所选的省Id
      nameProvince: '', // 所选的省
      valueCity: '', // 所选的市Id
      nameCity: '', // 所选的市
      valueOrigin: '', // 所选的区Id
      nameOrigin: '', // 所选的区
      cityOptions: [], // 市下拉框数据
      originOptions: [],// 区下拉框数据
      rules: {
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入正确的姓名', trigger: 'blur' }
        ],
          valueProvince: [{ required: true, message: '请选择省', trigger: 'change' }],
          valueCity: [{ required: true, message: '请选择市', trigger: 'change' }],
          valueOrigin: [{ required: true, message: '请选择区', trigger: 'change' }],
        xiangxi: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          {
            min: 3,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
        number: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入有效的手机号', trigger: 'blur' }
        ],
      },
    };
  },
  components: {
    'settlement-consignee-node': settlement_consignee_node,
  },
  mounted() {
   this.getlist('')
  },
  created(){
    this._getJsonData();
  },
  methods: {
    // 查询收件人信息
    onSearchReceiver(){
      this.getlist(this.searchVal);
    },
    getlist(searchPar){
       getDeliveryAddressList(searchPar).then((res) => {
      if (res.data == '') {
        this.$message.warning({
          message: '未查到相关收货地址！',
        });
        this.list = [];
        this.adddress = '';
        this.$emit('currentAddress', this.adddress);
        return;
      }else{
        this.list = res.data;
        this.adddress = this.list[0];
        this.$emit('currentAddress', this.adddress);
      }
    });
    },
     handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
    add() {
      this.dialogFormVisible = true;
      // this.$router.push({
      //   name: 'Address',
      // });
    },
    selectAddress(index) {
      this.selectIndex = index;
      this.adddress = this.list[index];
      this.$emit('currentAddress', this.adddress);
    },
    handleCreateAddress() {
      this.$router.push({
        name: 'Address',
        params: {},
      });
    },
    submitForm(formName) {
      this.form.regionId=this.valueProvince;
      this.form.region=this.nameProvince;
      this.form.shiId=this.valueCity;
      this.form.shi=this.nameCity;
      this.form.quId=this.valueOrigin;
      this.form.qu=this.nameOrigin;
      this.$refs[formName].validate((valid) => {
            if (valid) {
              post('api/address/insertAddress', {
                receiverName: this.form.name,
                receiverPhone: this.form.number,
                receiverProvinceId: this.form.regionId,
                receiverProvince: this.form.region,
                receiverCityId: this.form.shiId,
                receiverCity: this.form.shi,
                receiverDistrictId: this.form.quId,
                receiverDistrict: this.form.qu,
                receiverAddress: this.form.xiangxi,
                receiverFixedTelephone: this.form.dianhua,
                postcode: this.form.email,
              }).then((res) => {
                this.$message.success({
                  message: '新增成功',
                });
                  // 清空表单
                  this.form={
                      name: '',
                      regionId:'',
                      region: '',
                      xiangxi: '',
                      number: '',
                      dianhua: '',
                      email: '',
                      bieming: '',
                      shiId:'',
                      shi: '',
                      quId:'',
                      qu: '',
                      valueProvince:'',
                      valueCity:'',
                      valueOrigin:''
                  }
                //this.$router.go(0)
               this.getlist('')
              });
              this.dialogFormVisible = false;
            } else {
              return false;
            }
          });
    },
     // 选择省
    changeProvince(val) {
      this.provinceList.forEach((province, index) => {
        if (val.toString() === this.provinceList[index].value) {
            this.valueProvince=this.provinceList[index].value;
            this.nameProvince=this.provinceList[index].label;
            this.cityOptions = this.provinceList[index].children;
            this.originOptions = this.provinceList[index].children[0].children;
            this.valueCity = '';
            this.valueOrigin = '';
          // 如果选中省之后想市区默认选中
          // this.valueCity = this.provinceList[index].children[0].value;
          // this.nameCity = this.provinceList[index].children[0].label;
          // this.valueOrigin = this.provinceList[index].children[0].children[0].value;
          // this.nameOrigin=this.provinceList[index].children[0].children[0].label;
        }
      })
    },
    // 选择市
    changeCity(val) {
      this.cityList.forEach((city, index) => {
        if (val.toString() === this.cityList[index].value) {
            this.valueCity=this.cityList[index].value;
            this.nameCity=this.cityList[index].label;
            this.originOptions = this.cityList[index].children;
            this.valueOrigin = '';
          //如果选中市之后默认区选中
          // this.valueOrigin = this.cityList[index].children[0].value;
          // this.nameOrigin=this.cityList[index].children[0].label;
        }
      })
    },
    // 选择区
    changeOrigin(val) {
      this.originList.forEach((origin, index) => {
        if (val.toString() === this.originList[index].value) {
          this.valueOrigin = this.originList[index].value;
          this.nameOrigin=this.originList[index].label;
        }
      })
    },
     // 初始化省市区数据
    _getJsonData() {
      this.provinceList = []
      this.cityList = []
      this.originList = []
      chinaAddress().forEach((item) => {
        if (item.value.match(/0000$/)) {
          this.provinceList.push({
            value: item.value,
            label: item.name,
            children: []
          })
        } else if (item.value.match(/00$/)) {
          this.cityList.push({
            value: item.value,
            label: item.name,
            children: []
          })
        } else {
          this.originList.push({
            value: item.value,
            label: item.name
          })
        }
      })
      for (let index in this.provinceList) {
        for (let index1 in this.cityList) {
          if (this.provinceList[index].value.slice(0, 2) === this.cityList[index1].value.slice(0, 2)) {
            this.provinceList[index].children.push(this.cityList[index1])
          }
        }
      }
      for(let item1 in this.cityList) {
        for(let item2 in this.originList) {
          if (this.originList[item2].value.slice(0, 4) === this.cityList[item1].value.slice(0, 4)) {
            this.cityList[item1].children.push(this.originList[item2])
          }
        }
      }
    },
  },
};
</script>

<style>
a,
dl,
dt,
dd {
  list-style: none;
  text-decoration: none;
  margin: 0;
}
.address-list::after {
  visibility: hidden;
  clear: both;
  content: '';
  display: block;
  height: 0;
}
.address-btn a {
  display: block;
  height: 18px;
  line-height: 18px;
  padding: 5px 10px;
  width: 120px;
  border: 1px solid #ddd;
  text-align: center;
  color: #666;
  font-size: 12px;
}
.address-list {
  margin-bottom: 10px;
}
.address-list:hover {
  background-color: #f5f7fa;
}
.address-content {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

.address-btn {
  float: left;
  background-color: #fff;
  margin-right: 10px;
}
.address-content span {
  margin-right: 5px;
}

.address-btn-select a,
.address-btn a:hover {
  border: 2px solid #275af2;
  padding: 4px 9px;
}
</style>

<style lang="scss" scoped>
.settlement-consignee {
  margin: 10px 0px;
  width: 100%;

  .title {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 20px;
    position: relative;

    &-add {
      position: absolute;
      right: 0px;
      border: 0px;
      background: transparent;
      color: #1950ff;
      padding: 0px;
      height: 100%;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }

  .content {
    width: 100%;

    &-more {
      padding: 2px 20px;
      position: relative;
      display: inline-block;
    }
  }
}
.el-input {
    position: relative;
    font-size: 12px;
    display: inline-block;
    width: 15% !important;
}
</style>
