<template>
  <div class="gift-item">
    <svg class="image">
      <image x="0" y="0" width="100%" height="100%" xlink:href="../../assets/settlement/gift.png"></image>
      <image x="140" y="12" width="15" height="15" xlink:href="../../assets/settlement/gift-select-active.png"
        v-if="active"></image>
      <image x="140" y="12" width="15" height="15" xlink:href="../../assets/settlement/gift-select.png" v-if="!active">
      </image>
      <text x="33" y="61" class="money">5.00</text>
      <text x="20" y="61" class="money-company">￥</text>
      <text x="83" y="59" class="money-limit">全品类可用</text>
      <text x="20" y="84" class="money-term">有效期至2020.11.30</text>
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      active: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  .gift-item {
    display: inline-block;
    width: 180px;
    /*no*/
    margin: 15px 0px;

    .image {
      height: 105px;
      /*no*/
      width: 180px;
      /*no*/

      .money {
        fill: #1b50ff;
        font-size: 20px;
        font-weight: 800;
      }

      .money-company {
        fill: #1b50ff;
        font-size: 17px;
        font-weight: 800;
      }

      .money-limit {
        fill: #1b50ff;
        font-size: 12px;
      }

      .money-term {
        fill: #1b50ff;
        font-size: 12px;
      }
    }
  }
</style>