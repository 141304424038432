import { render, staticRenderFns } from "./settlement-discount.vue?vue&type=template&id=5dba1686&scoped=true"
import script from "./settlement-discount.vue?vue&type=script&lang=js"
export * from "./settlement-discount.vue?vue&type=script&lang=js"
import style0 from "./settlement-discount.vue?vue&type=style&index=0&id=5dba1686&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dba1686",
  null
  
)

export default component.exports