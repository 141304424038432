<template>
  <div class="settlement-invoice">
    <div class="title">
      <span>优惠券/礼品卡</span>
    </div>
    <div class="content">
      <div class="tltle">
        <div class="tltle-item" :class="{'active':activey==1}" @click="handleTab(1)">
          <span>全部优惠券</span>
        </div>
        <div class="tltle-item" :class="{'active':activey==2}" @click="handleTab(2)">
          <span>购物卡</span>
        </div>
      </div>
      <div class="content">
        <div class="content-item invoice" v-if="activey==1">
          <div class="invoice-item" v-for="(item, index) in couponList" :key="index" @click="couponClick(index)">
            <svg class="image" width="180" height="105">
              <image v-if="select[index]" x="0" y="0" width="100%" height="100%"
                xlink:href="../../assets/settlement/invoice_optional.png"></image>
              <image v-if="!select[index]" x="0" y="0" width="100%" height="100%"
                xlink:href="../../assets/settlement/invoice.png"></image>
              <image v-if="select[index] == true && active[index] == true" x="140" y="12" width="15" height="15"
                xlink:href="../../assets/settlement/gift-select-active.png"></image>
              <image v-if="select[index] == true && active[index] == false" x="140" y="12" width="15" height="15"
                xlink:href="../../assets/settlement/gift-select.png"></image>
              <text x="30" y="38" class="money" width="35" height="26">
                {{item.discountAmount}}
              </text>
              <text x="19" y="34" class="money-symbol" width="11" height="16">
                ￥
              </text>
              <text x="60" y="36" class="money-limit" width="35" height="26">
                满{{item.orderAmount}}可用
              </text>
              <text x="19" y="60" class="money-term" width="35" height="26">
                有效期至{{item.useEndTime}}
              </text>
              <text x="15" y="97" class="money-type" width="35" height="26">
                【限店铺】
              </text>
              <text x="80" y="97" class="money-wares" width="35" height="26" v-if="item.useRange == '0'">
                【限类型】
              </text>
              <text x="80" y="97" class="money-wares" width="35" height="26" v-if="item.useRange == '1'">
                【限商品】
              </text>
              <text x="80" y="97" class="money-wares" width="35" height="26" v-if="item.useRange == '2'">
                【全品类】
              </text>
            </svg>
            <span class="tips" v-if="!select[index]">已经使用了其他类型优惠券</span>
          </div>
        </div>
        <div class="guk" v-if="activey==2">
          <div class="guk-tltle">
            <div class="guk-tltle-item" :class="{'active':guk_activey==1}" @click="handleGukTab(1)">
              <span>已领取</span>
            </div>
            <div class="guk-tltle-item" :class="{'active':guk_activey==2}" @click="handleGukTab(2)">
              <span>领取购物卡</span>
            </div>
          </div>
          <div class="content">
            <div class="content-item invoice" v-if="guk_activey==1">
              <div class="gift-item" v-for="(item, index) in GukList" :key="index" @click="giftClick(index)">
                <svg class="image">
                  <image v-if="Gukselect[index]" x="0" y="0" width="100%" height="100%"
                    xlink:href="../../assets/settlement/gift.png"></image>
                  <image v-if="!Gukselect[index]" x="0" y="0" width="100%" height="100%"
                    xlink:href="../../assets/settlement/gift_un.png"></image>
                  <image v-if="Gukselect[index] == true && Gukactive[index] == true" x="140" y="12" width="15"
                    height="15" xlink:href="../../assets/settlement/gift-select-active.png"></image>
                  <image v-if="Gukselect[index] == true && Gukactive[index] == false" x="140" y="12" width="15"
                    height="15" xlink:href="../../assets/settlement/gift-select.png"></image>
                  <text x="33" y="61" class="money">{{item.discountAmount}}</text>
                  <text x="20" y="61" class="money-company">￥</text>
                  <text x="83" y="59" class="money-limit">满{{item.orderAmount}}可用</text>
                  <text x="20" y="84" class="money-term">有效期至{{item.endTime}}</text>
                </svg>
              </div>
            </div>
            <el-form ref="form" :model="form" :inline="true" label-width="80px" style="width:700px"
              v-if="guk_activey==2">
              <el-form-item label="购物卡ID">
                <el-input v-model="form.cardCode" style="width:200px"></el-input>
              </el-form-item>
              <el-form-item label="卡密">
                <el-input v-model="form.carmichael" show-password style="width:200px"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="text">优惠券金额抵用</span>
        <span class="money">-{{price}}</span>
        <span class="text" style="margin-left:30px;">购物卡金额抵用</span>
        <span class="money">-{{cardPrice}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import gift from './settlement-discount-gift';
  import {
    toDecimal2
  } from '@/utils/numberUtils.js';
  import {
    get,
    post
  } from '@/utils/request';
  import {
    getOrderTemp
  } from '@/api/order.js';
  export default {
    props: ['buyFrom'],
    components: {
      gift
    },
    data() {
      return {
        activey: 1,
        price: 0.00,
        select: [],
        couponList: [],
        active: [],
        form: {
          cardCode: '',
          carmichael: '',
        },
        shopCard: [],
        storeCouP: [],
        cardPrice: 0,
        guk_activey: 1,
        Gukselect: [],
        GukList: [],
        Gukactive: [],
        data: [],
      };
    },
    created() {
      getOrderTemp().then((res) => {
        this.data = res.data;
        get('api/coupon/getAvailableShopSpuCouponListBySppuIds').then((res) => {
          this.couponList = res.data.data;
          if (this.couponList != null && this.couponList.length > 0) {
            for (var i = 0; i < this.couponList.length; i++) {
              this.$set(this.select, i, true)
              this.$set(this.active, i, false)
            }
          }
        });
        get('api/storeshopcard/selectShopCardRecord').then((res) => {
          this.GukList = res.data.data;
          if (this.GukList != null && this.GukList.length > 0) {
            for (var i = 0; i < this.GukList.length; i++) {
              this.$set(this.Gukselect, i, true)
              this.$set(this.Gukactive, i, false)
            }
          }
          this.giftCheck();
        });
      });
    },
    methods: {
      giftClick(index) {
        if (this.Gukselect[index]) {
          this.$set(this.Gukactive, index, !this.Gukactive[index])
          if (this.Gukactive[index]) { //选中
            this.giftCheck();
          } else { //取消选中
            this.giftUnCheck(index);
          }
        }
        this.cardPriceSum();
      },
      giftCheck() {
        //选中购物卡后，需要校验总金额减去购物卡订单额度，是否满足其他购物卡使用
        //获取所有选中的订单限制之和
        for (let index in this.data) {
          let shopPrice = 0;
          let storeId = this.data[index].storeId; //获取storeId
          if (this.GukList != null && this.GukList.length > 0) {
            for (let i = 0; i < this.GukList.length; i++) {
              //判断storId是否相同，是否已经选中，计算订单金额总和
              if (this.Gukselect[i] && this.Gukactive[i] && storeId == this.GukList[index].storeId) { //判断是否storeId是否相同
                shopPrice = this.GukList[i].orderAmount * 1 + shopPrice;
              }
            }
            shopPrice = this.data[index].storePrice * 1 - shopPrice;

            for (let i = 0; i < this.GukList.length; i++) {
              //判断storId是否相同，未选中
              if (!this.Gukactive[i] && storeId == this.GukList[index].storeId) { //判断是否storeId是否相同
                if (this.GukList[i].orderAmount * 1 > shopPrice) { //剩余金额小于订单额度限制
                  this.$set(this.Gukselect, i, false)
                }
              }
            }
          }
        }
      },
      giftUnCheck(index) {
        let shopPrice = 0;
        let storeId = this.GukList[index].storeId; //获取购物卡storeId
        //获取本店铺购物卡优惠金额限制总和
        if (this.GukList != null && this.GukList.length > 0) {
          for (let i = 0; i < this.GukList.length; i++) {
            //判断storId是否相同，是否已经选中，计算订单金额总和
            if (this.Gukselect[i] && this.Gukactive[i] && storeId == this.GukList[i].storeId) { //判断是否storeId是否相同
              shopPrice = this.GukList[i].orderAmount * 1 + shopPrice;
            }
          }
        }
        //获取本店铺订单金额总和
        for (let index2 in this.data) {
          if (this.data[index2].storeId == storeId) {
            shopPrice = this.data[index2].storePrice * 1 - shopPrice;
          }
        }

        //遍历所有已禁用本店铺的购物卡
        if (this.GukList != null && this.GukList.length > 0) {
          for (let i = 0; i < this.GukList.length; i++) {
            //判断storId是否相同，是否已经选中，计算订单金额总和
            if (!this.Gukselect[i] && storeId == this.GukList[i].storeId && this.GukList[i].orderAmount * 1 <=
              shopPrice) { //判断是否storeId是否相同
              this.$set(this.Gukselect, i, true)
            }
          }
        }
      },
      cardPriceSum() { //核算购物卡金额，核算优惠券金额
        this.cardPrice = 0;
        this.price = 0;
        for (var j = 0; j < this.data.length; j++) {
          var store = this.data[j]; //本店铺
          var storeCoupon = 0; //本店铺对应的优惠券金额
          var cardPrice = 0; //本店铺购物卡金额
          for (var i = 0; i < this.active.length; i++) { //优惠券金额
            if (this.active[i] && this.couponList[i].storeId == store.storeId) {
              if (this.couponList[i].orderPrice * 1 < this.couponList[i].discountAmount * 1) {
                this.price = toDecimal2(this.price * 1 + this.couponList[i].orderPrice * 1);
                storeCoupon = storeCoupon * 1 + this.couponList[i].orderPrice * 1;
              } else {
                this.price = toDecimal2(this.price * 1 + this.couponList[i].discountAmount * 1);
                storeCoupon = storeCoupon * 1 + this.couponList[i].discountAmount * 1;
              }
            }
          }

          for (var i = 0; i < this.Gukactive.length; i++) { //购物卡金额
            if (this.Gukactive[i] && this.GukList[i].storeId == store.storeId) {
              //计算本店铺金额之和
              cardPrice = this.GukList[i].discountAmount * 1 + cardPrice;
            }
          }
          var cardPrice2= 0;
          if (cardPrice > 0) {
            cardPrice2 = store.storePrice * 1 - storeCoupon * 1 - cardPrice * 1;
            if (cardPrice2 < 0) {
              this.cardPrice = toDecimal2(store.storePrice * 1 - storeCoupon * 1);
            } else {
              this.cardPrice = toDecimal2(cardPrice);
            }
          } else {
            this.cardPrice = toDecimal2(0);
          }

        }
        this.$parent.setcouponPrice();
      },
      onSubmit() {
        if (this.form.cardCode == null || this.form.cardCode == '' || this.form.cardCode.trim() == '') {
          this.$message.error({
            message: '购物卡ID不得为空',
          });
        }
        if (this.form.carmichael == null || this.form.carmichael == '' || this.form.carmichael.trim() == '') {
          this.$message.error({
            message: '卡密不得为空',
          });
        }
        post('api/storeshopcard/checkShopStoreShopCardMemberRecord', this.form).then((res) => {
          var data = res.data;
          if (data.code == 0) {
            //push，把新增购物卡放到购物卡列表中
            this.$set(this.GukList, this.GukList.length, data.data)
            this.$set(this.Gukselect, this.Gukselect.length, true)
            this.$set(this.Gukactive, this.Gukactive.length, false)
            this.giftCheck();
            this.form.cardCode = '';
            this.form.carmichael = '';
            this.$message.success({
              message: '领取成功',
            });
          } else if (data.code == 301) {
            if (data.msg == '0') {
              this.$message.error({
                message: '请输入ID和卡密',
              });
            }
            if (data.msg == '1') {
              this.$message.error({
                message: 'ID和卡密输入错误',
              });
            } else if (data.msg == '3') {
              this.$message.error({
                message: '该订单不存在',
              });
            } else if (data.msg == '8') {
              this.$message.error({
                message: '购物卡已被您领取',
              });
            } else if (data.msg == '9') {
              this.$message.error({
                message: '购物卡已被其他账号领取',
              });
            } else if (data.msg == '4') {
              this.$message.error({
                message: '购物卡已使用，请重新输入',
              });
            } else if (data.msg == '5') {
              this.$message.error({
                message: '购物卡已过期，请重新输入',
              });
            } else if (data.msg == '6') {
              this.$message.error({
                message: '购物卡已使用，请重新输入',
              });
            } else if (data.msg == '7') {
              this.$message.error({
                message: '店铺不满足使用条件',
              });
            }
          } else {
            this.$message.error({
              message: '操作失败',
            });
          }
        });
      },
      handleTab(index) {
        this.activey = index;
      },
      handleGukTab(index) {
        this.guk_activey = index;
      },
      couponClick(index) { //序号
        if (this.select[index]) {
          this.$set(this.active, index, !this.active[index])
          if (this.active[index]) { //选中
            this.couponCheck(index);
          } else { //取消选中
            this.couponUnCheck(index);
          }
        }
        //计算金额
        this.cardPriceSum();
      },
      couponCheck(index) { //选中
        let couppon = this.couponList[index];
        if (couppon.useRange == '2') { //全品类 只要是本店铺的就全部禁用
          for (var i = 0; i < this.couponList.length; i++) {
            if (i != index) {
              if (couppon.storeId == this.couponList[i].storeId) {
                this.$set(this.select, i, false)
              }
            }
          }
        } else {
          for (var i = 0; i < this.couponList.length; i++) { //只要是本店铺的全品类就全部禁用
            if (i != index) {
              if (couppon.storeId == this.couponList[i].storeId && this.couponList[i].useRange == '2') {
                this.$set(this.select, i, false)
              }
            }
          }
          if (couppon.useRange == '0') { //我是商品类型
            for (var i = 0; i < this.couponList.length; i++) {
              let flag = true;
              if (i != index) {
                if (couppon.storeId == this.couponList[i].storeId && this.couponList[i].useRange != '2') {
                  if (this.couponList[i].useRange == '0') { //对方是商品类型，拿我和对方的商品类型上级进行比较 list, 还有就是和对方的统计进行比较
                    let categoryList = couppon.list; //我的类型
                    let categoryList2 = this.couponList[i].list; //对方的类型
                    for (var j = 0; j < categoryList.length; j++) {
                      for (var k = 0; k < categoryList2.length; k++) {
                        if (categoryList[i].catId == categoryList2[k].catId) {
                          flag = false; //我和对方有同样的商品类型
                        }
                        var ancestorss = categoryList2.ancestors.split(","); //对方的上级类型
                        for (var l = 0; l < ancestorss.length; l++) {
                          if (ancestorss[l] != '' && ancestorss[l] == categoryList[j].catId) { //我的商品类型和对方的商品类型上级一致
                            flag = false;
                          }
                        }
                        var ancestorss2 = categoryList.ancestors.split(","); //我的上级类型
                        for (var l = 0; l < ancestorss2.length; l++) {
                          if (ancestorss2[l] != '' && ancestorss2[l] == categoryList2[k].catId) { //我的上级商品类型和对方的商品类型一致
                            flag = false;
                          }
                        }
                      }
                    }
                  } else if (this.couponList[i].useRange == '1') { //对方是商品，拿我的商品类型与对方的上级商品类型比较
                    let categoryList = couppon.list; //我的类型
                    let categoryList2 = this.couponList[i].list; //对方的类型
                    for (var j = 0; j < categoryList.length; j++) {
                      for (var k = 0; k < categoryList2.length; k++) {
                        var ancestorss = categoryList2[k].ancestors.split(","); //对方的上级类型
                        for (var l = 0; l < ancestorss.length; l++) {
                          if (ancestorss[l] != '' && ancestorss[l] == categoryList[j].catId) { //我的商品类型和对方的商品类型上级一致
                            flag = false;
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (!flag) {
                this.$set(this.select, i, false)
              }
            }
          } else if (couppon.useRange == '1') { //我是商品
            for (var i = 0; i < this.couponList.length; i++) {
              let flag = true;
              if (i != index) {
                if (couppon.storeId == this.couponList[i].storeId && this.couponList[i].useRange != '2') { //统一店铺，不是全品类
                  if (this.couponList[i].useRange == '0') { //对方是商品类型， 拿对方的商品类型额我的上级类型作比较
                    let categoryList = couppon.list; //我的类型
                    let categoryList2 = this.couponList[i].list; //对方的类型
                    for (var j = 0; j < categoryList.length; j++) {
                      for (var k = 0; k < categoryList2.length; k++) {
                        var ancestorss = categoryList[j].ancestors.split(","); //我的上级类型
                        for (var l = 0; l < ancestorss.length; l++) {
                          if (ancestorss[l] != '' && ancestorss[l] == categoryList2[k].catId) { //我的上级类型和对方的商品类型一致
                            flag = false;
                          }
                        }
                      }
                    }
                  } else if (this.couponList[i].useRange == '1') { //对方是商品
                    let categoryList = couppon.list; //我的类型
                    let categoryList2 = this.couponList[i].list; //对方的类型
                    for (var j = 0; j < categoryList.length; j++) {
                      for (var k = 0; k < categoryList2.length; k++) {
                        if (categoryList[j].catId == categoryList2[k].catId) { //我的商品类型和对方的商品类型一致
                          flag = false;
                        }
                      }
                    }
                  }
                }
              }
            }
            if (!flag) {
              this.$set(this.select, i, false)
            }
          }
        }
      },
      couponUnCheck(index) { //取消选中
        //遍历本店铺所有禁用， 并循环每一个未禁用，来判断是否解禁
        for (var i = 0; i < this.select.length; i++) {
          if (!this.select[i]) { //禁用掉的
            var flag = true;
            for (var j = 0; j < this.active.length; j++) {
              if (this.active[j]) { //已选中
                var coupon = this.couponList[i]; //已禁用，我
                var coupon2 = this.couponList[j]; //已选中，对方
                if (coupon.storeId == coupon2.storeId) { //同一店铺
                  if (coupon.useRange != '2' && coupon2.useRange == "2") { //都不是全品类，否则必须禁用掉其中一个
                    let categoryList = coupon.list;
                    let categoryList2 = coupon2.list;
                    if (coupon.useRange == '0') { //我是商品类型
                      if (coupon2.useRange == '0') { //对方是商品类型
                        for (var k = 0; k < categoryList.length; k++) {
                          for (var l = 0; l < categoryList2.length; l++) {
                            if (categoryList[k].catId == categoryList2[l].catId) { //我和对方的本级相同
                              flag = false;
                            }
                            var ancestorss = categoryList[k].ancestors.split(','); //我的上级
                            var ancestorss2 = categoryList2[l].ancestors.split(','); //对方的上级
                            for (var m = 0; m < ancestorss.length; m++) {
                              if (ancestorss[m] != '' && ancestorss[m] == categoryList2[l].catId) { //我的上级和对方的本级
                                flag = false;
                              }
                            }
                            for (var m = 0; m < ancestorss2.length; m++) {
                              if (ancestorss2[m] != '' && ancestorss2[m] == categoryList[k].catId) { //我的本级和对方的上级
                                flag = false;
                              }
                            }
                          }
                        }
                      } else if (coupon2.useRange == '1') { //对方是商品
                        for (var k = 0; k < categoryList.length; k++) {
                          for (var l = 0; l < categoryList2.length; l++) {
                            var ancestorss = categoryList2[l].ancestors.split(',');
                            for (var m = 0; m < ancestorss.length; m++) {
                              if (ancestorss[m] != '' && ancestorss[m] == categoryList[k].catId) { //我和对方的上级类型一致
                                flag = false;
                              }
                            }
                          }
                        }
                      }
                    } else if (oupon.useRange == '1') { //我是商品
                      if (oupon2.useRange == '0') { //对方是商品类型
                        for (var k = 0; k < categoryList.length; k++) {
                          for (var l = 0; l < categoryList2.length; l++) {
                            var ancestorss = categoryList[k].ancestors.split(',');
                            for (var m = 0; m < ancestorss.length; m++) {
                              if (ancestorss[m] != '' && ancestorss[m] == categoryList2[l].catId) { //我的上级类型和对方的类型一致
                                flag = false;
                              }
                            }
                          }
                        }
                      } else if (oupon2.useRange == '1') { //对方是商品
                        for (var k = 0; k < categoryList.length; k++) {
                          for (var l = 0; l < categoryList2.length; l++) {
                            if (categoryList[k].catId == categoryList2[l].catId) {
                              flag = false;
                            }
                          }
                        }
                      }
                    }
                  } else {
                    flag = false;
                  }
                }
              }
            }
            if (flag) {
              this.$set(this.select, i, true)
            }
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .settlement-invoice {
    margin: 10px 0px;
    width: 100%;

    >.title {
      font-size: 12px;
      font-weight: 800;
      margin-bottom: 20px;
      position: relative;
    }


    >.content {
      width: 100%;
      border: 1px solid #e5e5e5;

      .tltle {
        width: 100%;
        height: 42px;
        background: #f2f7fd;

        .tltle-item {
          display: inline-block;
          width: 137px;
          height: 100%;
          text-align: center;
          line-height: 42px;
          cursor: default;

          &.active {
            background: #fff;
            color: #1751fd;
          }
        }
      }

      .content {
        width: 100%;
        height: auto;
        min-height: 100px;

        .content-item {
          width: 100%;
          height: auto;
          margin-top: 20px;
          margin-bottom: 20px;
          // margin: 0px 34px;
          position: relative;
          padding: 5px 0px;

          display: grid;
          justify-content: space-around;
          grid-template-columns: repeat(auto-fill, 180px);
          /*no*/
          grid-gap: 5px 5px;
        }

      }

      .foot {
        height: 52px;
        background: #f2f7fd;
        font-weight: 800;
        padding-left: 26px;
        line-height: 52px;

        .text {
          color: #777777;
        }

        .money {
          margin-left: 10px;

          &:before {
            content: "￥";
          }

          color: #ee8220;
        }
      }
    }
  }

  .invoice-item {
    display: inline-block;
    width: 180px;
    height: 130px;
    /*no*/
    margin: 3px 0px;

    .image {
      height: 105px;
      /*no*/
      width: 180px;
      /*no*/

      .money {
        fill: #fff;
        font-size: 22px;
        font-weight: 800;

      }

      .money-symbol {
        fill: #fff;
        font-size: 15px;
        font-weight: 800;
      }

      .money-limit {
        fill: #fff;
        font-size: 11px;
      }

      .money-term {
        fill: #949494;
        font-size: 11px;
      }

      .money-type {
        fill: #949494;
        font-size: 14px;
      }

      .money-wares {
        fill: #949494;
        font-size: 14px;
      }


    }

    .tips {
      color: #999999;
      font-size: 12px;
      padding-left: 19px;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 6px;
        top: 2px;
        width: 12px;
        height: 12px;
        background: transparent url("../../assets/settlement/invoice-tips.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .gift-item {
    display: inline-block;
    width: 180px;
    /*no*/
    margin: 15px 0px;

    .image {
      height: 105px;
      /*no*/
      width: 180px;
      /*no*/

      .money {
        fill: #1b50ff;
        font-size: 20px;
        font-weight: 800;
      }

      .money-company {
        fill: #1b50ff;
        font-size: 17px;
        font-weight: 800;
      }

      .money-limit {
        fill: #1b50ff;
        font-size: 12px;
      }

      .money-term {
        fill: #1b50ff;
        font-size: 12px;
      }
    }
  }

  .guk {
    min-height: 100px;
    height: auto;
    width: 100%;

    .guk-tltle-item {
      display: inline-block;
      width: 80px;
      height: 100%;
      text-align: center;
      line-height: 30px;
      cursor: default;
      font-size: 13px;
    }

    .guk-tltle-item.active {
      color: #1751fd;
    }
  }

  .gift-item {
    display: inline-block;
    width: 180px;
    /*no*/
    margin: 15px 0px;

    .image {
      height: 105px;
      /*no*/
      width: 180px;
      /*no*/

      .money {
        fill: #1b50ff;
        font-size: 20px;
        font-weight: 800;
      }

      .money-company {
        fill: #1b50ff;
        font-size: 17px;
        font-weight: 800;
      }

      .money-limit {
        fill: #1b50ff;
        font-size: 12px;
      }

      .money-term {
        fill: #1b50ff;
        font-size: 12px;
      }
    }
  }
</style>