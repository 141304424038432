<template>
  <div class="settlement-payment">
    <div class="title">
      <span>可支付方式</span>
    </div>
    <div class="fangshi">
      <div class="content">
        <button class="settlement-button active">
          <span>公司转帐</span>
        </button>
      </div>
      <div class="content">
        <button class="settlement-button active">
          <span>微信扫码支付</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.settlement-payment {
  margin: 10px 0px;
  width: 100%;

  .title {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 20px;
    position: relative;

    &-add {
      position: absolute;
      right: 0px;
      border: 0px;
      background: transparent;
      color: #1950ff;
      padding: 0px;
      height: 100%;

      &:focus {
        outline: none;
      }
    }
  }
  .fangshi {
    display: flex;
  }
  .content {
    @import 'settlement-button';

    .settlement-button {
      margin-right: 10px;
    }
  }
}
</style>
