import {get} from '@/utils/request';


/***
 * 获取会员信息
 */
const getMemberInfo = function (){
    return get(`/api/member/getMemberInfo`)
        .then(res=>res.data).catch(err=>console.log(err))
}

/**
 * 获取客户经理下的会员
 */

const getMemberByManagerId = function (username){
    return get(`/api/manager/getMemberByManagerId?username=`+username)
        .then(res=>res.data).catch(err=>console.log(err))
}



export {getMemberInfo,getMemberByManagerId}
