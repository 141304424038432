<template>
  <div class="settlement-consignee-node">
    <button class="settlement-button" :class="{'active':active}" @click="handleClick">
      <span>李先生 山东</span>
    </button>
    <div class="settlement-address">
      <span>李先生 山东 历城 致远街道 中山东路中联花园B区 178****0921</span>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      active:Boolean
    },
    methods:{
      handleClick(){}
    }
  }
</script>

<style lang="scss" scoped>
  .settlement-consignee-node {
    height: 41px;
    width: 100%;
    color: #545454;
    margin: 10px 0px;
    .settlement-address{
      display: inline-block;
      padding-left: 20px;
    }
    @import "settlement-button";
  }
</style>
